<template>
  <ion-list v-if="loaded_resources != null">
    <ion-card
      v-for="item in loaded_resources.data"
      :key="item.id"
      :ref="`items-${item.id}`"
    >
      <ion-card-header>
        <ion-card-subtitle>
          {{ moment(item.start).format("ll") }}:
          {{ moment(item.start).format("LT") }} to
          {{ moment(item.end).format("LT") }}</ion-card-subtitle
        >
        <ion-card-title>
          Subjects: {{ item.exam_subjects_count }}
        </ion-card-title>
        <ion-card-subtitle>
          Started at: {{ getStartTime(item) }}
        </ion-card-subtitle>
        <ion-card-subtitle>
          Ended at: {{ getEndTime(item) }}
        </ion-card-subtitle>
      </ion-card-header>
      <ion-item>
        <ion-button fill="outline" slot="start" @click="gotoSubjects(item)">
          Subjects
        </ion-button>
        <ion-button
          v-if="item.btn"
          fill="outline"
          slot="end"
          :color="item.btn.color"
          :disabled="item.btn.disable"
          @click="controlExams(item)"
        >
          {{ item.btn.state }}
        </ion-button>
      </ion-item>
    </ion-card>
  </ion-list>
  <ion-list v-else>
    <ion-list-header>
      <p>No schedules found</p>
    </ion-list-header>
  </ion-list>
</template>
<script>
import {
  IonList,
  IonListHeader,
  alertController,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  // IonIcon,
  // IonLabel,
  IonButton,
  IonItem,
  loadingController,
} from "@ionic/vue";

import {
  trashOutline,
  trashSharp,
  createOutline,
  createSharp,
  ellipsisHorizontalOutline,
  ellipsisHorizontalSharp,
  checkmarkCircleOutline,
  checkmarkCircleSharp,
  pinOutline,
} from "ionicons/icons";

import moment from "moment";

export default {
  props: {
    resources: {
      type: Object,
      required: true,
    },
    exam_id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    exam_type: {
      type: String,
      required: true,
    },
  },

  components: {
    IonList,
    IonListHeader,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,

    // IonIcon,
    // IonLabel,
    IonButton,
    IonItem,
  },
  data() {
    return {
      trashOutline,
      trashSharp,
      createOutline,
      createSharp,
      ellipsisHorizontalOutline,
      ellipsisHorizontalSharp,
      checkmarkCircleOutline,
      checkmarkCircleSharp,
      pinOutline,

      updateModalState: false,

      moment,
      resourceToUpdate: {
        id: null,
        name: null,
      },

      loaded_resources: this.resources,

      xhrError: {
        header: null,
        description: null,
      },
    };
  },
  watch: {
    resources(newVal) {
      this.loaded_resources = newVal;
    },
  },
  methods: {
    slide(id) {
      this.$refs[`items-${id}`].$el.open();
    },

    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });

      return alert;
    },

    loader(message) {
      const loading = loadingController.create({
        message: message,
        backdropDismiss: false,
      });

      return loading;
    },

    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },

    itemStatus(item) {
      if (item.ended_at != null) {
        return "Ended";
      }
      return item.started_at != null ? "Started" : "Not Started";
    },

    getStartTime(item) {
      let start_time = item.started_at;

      if (start_time == null) {
        return `Not Started`;
      }

      return moment(start_time).format("lll");
    },

    getEndTime(item) {
      let start_time = item.started_at;

      if (start_time == null) {
        return `N/A`;
      }

      let end_time = item.ended_at;

      if (end_time == null) {
        return `Currently Active`;
      }

      return moment(end_time).format("lll");
    },

    async gotoSubjects(item) {
      let data = {
        exam_id: this.exam_id,
        exam_schedule_id: item.id,
        filter_only: true,
      };
      await this.$store.dispatch("examSubject/filters", data);

      this.$router.push({
        name: "exam.subject",
        params: {
          exam: this.exam_id,
          title: `${this.title}`,
        },
      });
    },

    async controlExams(item) {
      const spinner = await this.loader("Updating...");
      spinner.present();

      let data = {
        id: item.id,
        exam_schedule_id: item.id,
        exam_id: this.exam_id,
        status: item.btn.state,
      };

      try {
        await this.$store.dispatch("examSchedule/control", data);
      } catch (error) {
        this.setErrorResponse(error);
        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );
        alertDialog.present();
      } finally {
        spinner.dismiss();
      }
    },
  },

  mounted() {
    this.createBtn = setInterval(() => {
      const data = this.loaded_resources.data;
      data.forEach((resource) => {
        const start = resource.start;
        const end = resource.end;
        const now = moment();
        const started_at = resource.started_at;
        const ended_at = resource.ended_at;
        const closed_at = resource.closed_at;
        const index = data.indexOf(resource);

        let btn = {
          state: "",
          color: "medium",
          disable: true,
        };

        const is_online_exam = this.exam_type === "Online";

        if (started_at == null && is_online_exam) {
          btn.state = "start";
          btn.color = "success";
          if (now.format("x") >= moment(start).format("x")) {
            btn.disable = false;
          } else {
            btn.state = "Please Wait";
          }
        } else if (ended_at == null) {
          btn.state = "end";
          btn.color = "danger";
          if (now.format("x") >= moment(end).format("x")) {
            btn.disable = false;
          } else {
            btn.state = "Please Wait";
          }
        } else if (closed_at == null) {
          btn.state = "lock";
          btn.color = "danger";
          if (now.format("x") >= moment(end).format("x")) {
            btn.disable = false;
          }
        } else {
          btn.state = "locked";
        }
        this.loaded_resources.data[index].btn = btn;
      });
    }, 500);
  },

  unmounted: function () {
    clearInterval(this.createBtn);
  },
};
</script>