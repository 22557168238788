<template>
  <base-layout :title="title" :backLink="backLink" :reloadable="reloadable">
    <template v-slot:search-bar>
      <ion-toolbar>
        <ion-segment value="all" @ionChange="segmentChanged($event)">
          <ion-segment-button value="all">
            <ion-label>All</ion-label>
          </ion-segment-button>
          <ion-segment-button value="upcoming">
            <ion-label>Upcoming</ion-label>
          </ion-segment-button>
          <ion-segment-button value="active">
            <ion-label>Active</ion-label>
          </ion-segment-button>
          <ion-segment-button value="closed">
            <ion-label>Closed</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>
    </template>
    <ion-refresher slot="fixed" @ionRefresh="refresh($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <ion-grid fixed>
      <ion-row>
        <ion-col size="12">
          <list-schedule-for-control
            :resources="resources"
            :exam_id="exam"
            :title = "title"
            :exam_type="exam_type"
          ></list-schedule-for-control>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-infinite-scroll threshold="100px" @ionInfinite="loadData($event)">
      <ion-infinite-scroll-content
        loading-spinner="bubbles"
        loading-text="Loading more data..."
      >
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>

    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button @click="setFilterModalState(true)">
        <ion-icon :ios="funnelOutline" :md="funnelOutline"></ion-icon>
      </ion-fab-button>
    </ion-fab>
    <ion-modal
      :is-open="filterModalState"
      @didDismiss="setFilterModalState(false)"
    >
      <base-modal
        title="Filter Exam Schedule"
        @closeModal="setFilterModalState(false)"
      >
        <filter-schedule-for-control
          @closeModal="setFilterModalState(false)"
          :exam_id="exam"
        ></filter-schedule-for-control>
      </base-modal>
    </ion-modal>
  </base-layout>
</template>

<script>
import BaseLayout from "../../Base/BaseLayout";
import BaseModal from "../../Base/BaseModal";
import ListScheduleForControl from "../../Components/Exam/ExamControl/ListScheduleForControl.vue";
import FilterScheduleForControl from "../../Components/Exam/ExamControl/FilterScheduleForControl.vue";

import {
  IonGrid,
  IonRow,
  IonCol,
  IonFab,
  IonIcon,
  IonFabButton,
  IonModal,
  IonLabel,
  IonToolbar,
  IonSegment,
  IonSegmentButton,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/vue";

import {
  funnelOutline,
  chevronBackCircleOutline,
  addOutline,
} from "ionicons/icons";

export default {
  props: ["exam", "title", "exam_type"],

  components: {
    BaseLayout,
    BaseModal,
    ListScheduleForControl,
    FilterScheduleForControl,
    IonGrid,
    IonRow,
    IonCol,
    IonFab,
    IonIcon,
    IonFabButton,
    IonModal,
    IonLabel,
    IonToolbar,
    IonSegment,
    IonSegmentButton,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonRefresher,
    IonRefresherContent,
  },

  data() {
    return {
      backLink: "exam.list",
      reloadable: true,
      createModalState: false,
      filterModalState: false,

      funnelOutline,
      chevronBackCircleOutline,
      addOutline,
    };
  },

  computed: {
    resources() {
      return this.$store.getters["examSchedule/resources"];
    },
    shouldReload() {
      return this.$store.getters["reload"] === this.$route.name;
    },
  },

  watch: {
    shouldReload(current) {
      if (current) {
        this.refresh();
        this.$store.dispatch("clearReload");
      }
    },
  },

  methods: {
    async fetch(refresh = true) {
      await this.$store.dispatch("examSchedule/resources", {
        refresh: refresh,
        resourceId: this.exam,
      });
    },
    loadData(event) {
      this.fetch(false);
      event.target.complete();
    },
    async refresh(event) {
      await this.$store.dispatch("examSchedule/resources", {
        refresh: true,
        resourceId: this.exam,
      });
      if (event) {
        event.target.complete();
      }
    },
    setCreateModalState(state) {
      this.createModalState = state;
    },
    setFilterModalState(state) {
      this.filterModalState = state;
    },
    async segmentChanged(event) {
      await this.$store.dispatch("examSchedule/segment", {
        segment: event.target.value,
        exam_id: this.exam,
      });
    },
  },

  created() {
    this.fetch();
  },
};
</script>

<style scoped>
</style>
